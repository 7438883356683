import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import { Customers } from "./customersSlice";
import Can from "../../helpers/Can";
import dayjs from "dayjs";
const subscriptionsApi = factory.get("subscriptions");
const taskApi = factory.get("task");

const initialState = {
  subscriptions: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      mobile: "",
      customer_id: null,
      start_date: null,
      end_date: null,
      start_date_expiry: null,
      end_date_expiry: null,
      account_type_id: null,
      is_confirmed: null,
      city_id: null,
      expiry_date: null,
      is_expiry: null,
      client_id: null,
      is_free: null,
      customers_source: null,
      created_by_id: null,
      is_offer: null,
      is_renew: null,
      return_from_expire: null,
    },
    dialog: false,
  },
  subscription: {
    dialog: false,
    type: "add",
    loading: false,
    form: {
      id: "",
      customer_id: null,
      date: dayjs().format("YYYY-MM-DD"),
      account_type_id: null,
      amount: 0,
      note: "",
      offer_id: null,
      is_confirmed: 0,
    },
    errors: {},
  },
  subscribers_history: {
    data: [],
    loading: false,
  },
  amount_collectors: {
    data: {
      amount: 0,
      count: 0,
      transaction_count: 0,
      transaction_total: 0,
    },
    filters: {
      date: null,
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
    },
    loading: false,
  },
  installation_amount_summery: {
    data: {
      amount: 0,
      count: 0,
    },
    filters: {
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
    },
    loading: false,
  },
  subscription_customer_ftth_price: {
    price: 0,
    offer_id: null,
    message: null,
    loading: false,
  },
  renew: {
    dialog: false,
    loading: false,
    form: {
      customer_id: null,
      customer_name: null,
      ftth_subscription_id: "",
      account_type: "",
      price: 0,
      operation_type: "",
      last_expiry_date: null,
      ftth_name: "",
      message: "",
      offer_id: null,
    },
  },
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setSubscriptions: (state, { payload }) => {
      state.subscriptions.data = payload.data;
      state.subscriptions.total = payload.total;
      state.subscriptions.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.subscriptions.filters = {
        ...state.subscriptions.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.subscriptions.filters = initialState.subscriptions.filters;
    },
    setDialog: (state, { payload }) => {
      if (payload?.type === "edit") {
        state.subscription.type = "edit";
        state.subscription.form.id = payload.data.id;
        state.subscription.form.is_confirmed = payload.data.is_confirmed
          ? 1
          : 0;

        state.subscription.form.customer_id = {
          id: payload.data.customer_id,
          full_name: payload.data.customer_name,
        };
        state.subscription.form.date = payload.data.date;
        state.subscription.form.account_type_id = {
          id: payload.data.account_type_id,
          name: payload.data.account_type,
          price: payload.data.amount,
        };
        state.subscription.form.last_amount = payload.data.amount;
        state.subscription.form.note = payload.data.note || "";
      }
      if (payload?.type === "add_from_customer") {
        state.subscription.type = "add_from_customer";
        state.subscription.form.customer_id = {
          id: payload.data.id,
          full_name: payload.data.full_name,
        };
      }

      state.subscription.dialog = !state.subscription.dialog;
      //clear after close
      if (!state.subscription.dialog) {
        state.subscription.form = initialState.subscription.form;
        state.subscription.type = "add";
      }
    },
    setFiltersDialog: (state, { payload }) => {
      state.subscriptions.dialog = !state.subscriptions.dialog;
    },
    resetForm: (state) => {
      state.subscription.form = initialState.subscription.form;
    },
    setErrors: (state, { payload }) => {
      state.subscription.errors = payload;
    },
    setSubscriberHistory: (state, { payload }) => {
      state.subscribers_history.data = payload.data;
      state.subscribers_history.loading = false;
    },
    setAmountCollectors: (state, { payload }) => {
      state.amount_collectors.data = { ...payload.data };
    },
    setFiltersAmountCollectors: (state, { payload }) => {
      state.amount_collectors.filters = {
        ...state.amount_collectors.filters,
        ...payload,
      };
    },
    setInstallationAmountSummery: (state, { payload }) => {
      state.installation_amount_summery.data = { ...payload.data };
    },
    setFiltersInstallationAmountSummery: (state, { payload }) => {
      state.installation_amount_summery.filters = {
        ...state.installation_amount_summery.filters,
        ...payload,
      };
    },
    setSubscriptionCustomerFtthPrice: (state, { payload }) => {
      state.subscription_customer_ftth_price.price = payload?.price || 0;
      state.subscription_customer_ftth_price.offer_id =
        payload?.offer_id || null;
      state.subscription_customer_ftth_price.message = payload?.message || "";
    },
    resetSubscriptionCustomerFtthPrice: (state) => {
      state.subscription_customer_ftth_price.price = 0;
      state.subscription_customer_ftth_price.offer_id = null;
      state.subscription_customer_ftth_price.message = "";
    },
    setRenewDialog: (state, { payload }) => {
      state.renew.form.customer_id = payload?.customer_id || null;
      state.renew.form.customer_name = payload?.customer_name || null;
      state.renew.dialog = !state.renew.dialog;
    },
    setRenewInfo: (state, { payload }) => {
      state.renew.form.ftth_subscription_id = payload.ftth_subscription_id;
      state.renew.form.account_type = payload.account_type;
      state.renew.form.price = payload.price;
      state.renew.form.operation_type = payload.operation_type;
      state.renew.form.last_expiry_date = payload.last_expiry_date;
      state.renew.form.ftth_name = payload.ftth_name;
      state.renew.form.message = payload.message;
      state.renew.form.offer_id = payload.offer_id;
    },
  },
});

export const {
  setSubscriptions,
  setLoading,
  setFilters,
  resetFilters,
  setDialog,
  resetForm,
  setErrors,
  setFiltersDialog,
  setSubscriberHistory,
  setAmountCollectors,
  setFiltersAmountCollectors,
  setSubscriptionCustomerFtthPrice,
  resetSubscriptionCustomerFtthPrice,
  setRenewDialog,
  setRenewInfo,
  setInstallationAmountSummery,
  setFiltersInstallationAmountSummery,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
const toNumber = (value) => {
  return typeof value === "number" ? value : parseInt(value.replace(/,/g, ""));
};
//axios
const index =
  (params = null) =>
  async (dispatch, getState) => {
    try {
      const filters = getState().subscriptions.subscriptions.filters;
      const filters_params = {
        ...filters,
        account_type_id: filters.account_type_id?.id || null,
        customer_id: filters.customer_id?.id || null,
        city_id: filters.city_id?.id || null,
        client_id: filters.client_id?.id || null,
        created_by_id: filters.created_by_id?.id || null,
        ...params,
      };
      dispatch(setLoading("subscriptions"));
      const res = await subscriptionsApi.index(filters_params);
      dispatch(setSubscriptions(res.data));
    } catch (err) {
      dispatch(
        showNotification({
          message: err.response.data.errors,
          type: "error",
        })
      );
      dispatch(setLoading("subscriptions"));
      throw new Error(err);
    }
  };
const last = (customer_id) => async (dispatch) => {
  try {
    dispatch(setLoading("subscribers_history"));
    const res = await subscriptionsApi.last(customer_id);
    dispatch(setSubscriberHistory(res.data));
  } catch (err) {
    dispatch(setLoading("subscribers_history"));
    throw new Error(err);
  }
};
const confirm = (id) => async (dispatch) => {
  try {
    // dispatch(setLoading("subscribers_history"));
    await subscriptionsApi.confirm(id);
    dispatch(
      showNotification({
        message: "تم تأكيد الاشتراك بنجاح",
        type: "success",
      })
    );
    dispatch(index());
    // dispatch(setSubscriberHistory(res.data));
  } catch (err) {
    // dispatch(setLoading("subscribers_history"));
    throw new Error(err);
  }
};
const store = (data, formLocation) => async (dispatch, getState) => {
  try {
    const filters = getState().subscriptions.subscriptions.filters;
    dispatch(setLoading("subscription"));
    const form = {
      ...data,
      customer_id: data.customer_id ? data.customer_id?.id : null,
      account_type_id: data.account_type_id ? data.account_type_id?.id : null,
      amount: toNumber(data.amount),
      // is_confirmed: data.is_confirmed ? 1 : 0,
    };

    const res = data.id
      ? await subscriptionsApi.update(form)
      : await subscriptionsApi.create(form);
    dispatch(setLoading("subscription"));
    dispatch(
      showNotification({
        message: "تم تجديد الاشتراك بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());

    switch (formLocation) {
      case "customers":
        dispatch(Customers.index());
        break;
      case "customer-by-id":
        dispatch(index({ customer_id: data.customer_id?.id }));
        dispatch(Customers.getById(data.customer_id?.id));
        break;
      default:
        Can("subscription_view") && dispatch(index());
    }
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("subscription"));
    throw new Error(err);
  }
};
const deleteSubscription = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("subscriptions"));
    await subscriptionsApi.deleteSubscription(id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم حذف  بنجاح",
      })
    );
    dispatch(index());
    return {
      success: true,
    };
  } catch (err) {
    dispatch(setLoading("subscriptions"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const fastSubscribe = (task_id) => async (dispatch) => {
  try {
    await taskApi.fastSubscribe(task_id);
    dispatch(
      showNotification({
        message: "تم تجديد الاشتراك بنجاح",
        type: "success",
      })
    );
    return {
      status: true,
    };
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const toExcel = () => async (dispatch, getState) => {
  try {
    const filters = getState().subscriptions.subscriptions.filters;
    dispatch(setLoading("subscriptions"));
    const res = await subscriptionsApi.exportToExcel({
      ...filters,
      account_type_id: filters.account_type_id?.id || null,
      customer_id: filters.customer_id?.id || null,
      city_id: filters.city_id?.id || null,
      client_id: filters.client_id?.id || null,
      created_by_id: filters.created_by_id?.id || null,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "subscriptions.xlsx";
    a.click();
    dispatch(setLoading("subscriptions"));
  } catch (err) {
    dispatch(setLoading("subscriptions"));
    throw new Error(err);
  }
};
const checkFtthSubscriptionCustomerPrice =
  (customer_id, account_type_id) => async (dispatch) => {
    try {
      dispatch(setLoading("subscription_customer_ftth_price"));
      const res = await subscriptionsApi.checkFtthSubscriptionCustomerPrice(
        customer_id,
        account_type_id
      );
      dispatch(setSubscriptionCustomerFtthPrice(res.data.data[0]));
      dispatch(setLoading("subscription_customer_ftth_price"));
      dispatch(
        showNotification({
          message: "تم جلب السعر",
          type: "info",
        })
      );
    } catch (err) {
      dispatch(setLoading("subscription_customer_ftth_price"));
      dispatch(
        showNotification({
          message: err.response.data.errors[0],
          type: "error",
        })
      );
      throw new Error(err);
    }
  };
const checkRenew = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("renew"));
    const res = await subscriptionsApi.checkRenew(id);
    dispatch(setRenewInfo(res.data.data));
    dispatch(setLoading("renew"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("renew"));
    dispatch(setRenewDialog());
    throw new Error(err);
  }
};
const postRenew = () => async (dispatch, getState) => {
  try {
    const data = getState().subscriptions.renew.form;
    dispatch(setLoading("renew"));
    await subscriptionsApi.renew(data);
    dispatch(setLoading("renew"));
    dispatch(setRenewDialog());
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setLoading("renew"));
    throw new Error(err);
  }
};
const amountCollectors = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("amount_collectors"));
    const res = await subscriptionsApi.subscribeAmountCollectedStatistics(data);
    dispatch(setAmountCollectors(res.data));
    dispatch(setLoading("amount_collectors"));
  } catch (err) {
    dispatch(setLoading("amount_collectors"));
    throw new Error(err);
  }
};
const installationAmountSummery = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("installation_amount_summery"));
    const res = await subscriptionsApi.installationAmountSummary(data);
    dispatch(setInstallationAmountSummery(res.data));
    dispatch(setLoading("installation_amount_summery"));
  } catch (err) {
    dispatch(setLoading("installation_amount_summery"));
    throw new Error(err);
  }
};

export const Subscriptions = {
  index,
  store,
  last,
  confirm,
  deleteSubscription,
  amountCollectors,
  fastSubscribe,
  toExcel,
  checkFtthSubscriptionCustomerPrice,
  checkRenew,
  postRenew,
  installationAmountSummery,
};

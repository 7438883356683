import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const walletApi = factory.get("wallet");

const initialState = {
  wallet_transactions: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      start_date: null,
      end_date: null,
      from_type: null,
      note: "",
      user_id: null,
    },
    dialog: false,
  },
  user_wallet: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      is_active: null,
    },
    dialog: false,
  },
  transaction_from_user: {
    dialog: false,
    loading: false,
    form: {
      form_type: "from-user",
      from_user_id: null,
      user_wallet: 0,
      amount: "",
      note: "",
      safe_id: null,
    },
    errors: {},
  },
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletTransactionData: (state, { payload }) => {
      state.wallet_transactions.data = payload.data;
      state.wallet_transactions.total = payload.total;
    },
    setUserWalletData: (state, { payload }) => {
      state.user_wallet.data = payload.data;
      state.user_wallet.total = payload.total;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.wallet_transactions.filters = {
        ...state.wallet_transactions.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.wallet_transactions.filters =
        initialState.wallet_transactions.filters;
    },
    setFiltersDialog: (state, { payload }) => {
      state.wallet_transactions.dialog = !state.wallet_transactions.dialog;
    },
    setUserTransactionsFilters: (state, { payload }) => {
      state.wallet_transactions.filters = {
        ...state.wallet_transactions.filters,
        ...payload,
      };
    },
    setTransactionFromDialog(state, { payload }) {
      if (payload?.id) {
        state.transaction_from_user.form.form_type = payload.form_type;
        state.transaction_from_user.form.user_wallet = payload.user_wallet || 0;
        state.transaction_from_user.form.from_user_id = {
          id: payload.id,
          full_name: payload.full_name,
        };
      }
      state.transaction_from_user.dialog = !state.transaction_from_user.dialog;
    },

    //user wallets filters
    setUserWalletFilters: (state, { payload }) => {
      state.user_wallet.filters = {
        ...state.user_wallet.filters,
        ...payload,
      };
    },
    resetUserWalletFilters: (state) => {
      state.user_wallet.filters = initialState.user_wallet.filters;
    },
    setUserWalletFiltersDialog: (state) => {
      state.user_wallet.dialog = !state.user_wallet.dialog;
    },
  },
});

export const {
  setWalletTransactionData,
  setLoading,
  setFilters,
  setFiltersDialog,
  resetFilters,
  setUserTransactionsFilters,
  setTransactionFromDialog,
  setUserWalletData,
  setUserWalletFilters,
  resetUserWalletFilters,
  setUserWalletFiltersDialog,
} = walletSlice.actions;

export default walletSlice.reducer;

const toNumber = (value) => {
  return typeof value === "number" ? value : parseInt(value.replace(/,/g, ""));
};

//for wallet transactions
export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().wallet.wallet_transactions.filters;
    const filters_params = {
      ...filters,
      user_id: filters.user_id?.id || null,
    };
    dispatch(setLoading("wallet_transactions"));
    const res = await walletApi.index(filters_params);
    dispatch(setWalletTransactionData(res.data));
    dispatch(setLoading("wallet_transactions"));
  } catch (err) {
    dispatch(setLoading("wallet_transactions"));
    throw new Error(err);
  }
};
export const indexUserWallet = () => async (dispatch, getState) => {
  try {
    const filters = getState().wallet.user_wallet.filters;
    const filters_params = {
      ...filters,
    };
    dispatch(setLoading("user_wallet"));
    const res = await walletApi.indexUserWallet(filters_params);
    dispatch(setUserWalletData(res.data));
    dispatch(setLoading("user_wallet"));
  } catch (err) {
    dispatch(setLoading("user_wallet"));
    throw new Error(err);
  }
};
export const createTransactionFromUser = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("transaction_from_user"));
    await walletApi.transactionFromUsers({
      from_user_id: data.from_user_id?.id || null,
      amount: toNumber(data.amount),
      note: data.note || "",
    });
    dispatch(setLoading("transaction_from_user"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم سحب الرصيد من المستخدم بنجاح",
      })
    );
    dispatch(setTransactionFromDialog());
    dispatch(indexUserWallet());
  } catch (err) {
    dispatch(setLoading("transaction_from_user"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
export const createTransactionToSafe = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("transaction_from_user"));
    await walletApi.transactionToSafe({
      from_user_id: data.from_user_id?.id || null,
      safe_id: data.safe_id?.id,
      amount: toNumber(data.amount),
      note: data.note || "",
    });
    dispatch(setLoading("transaction_from_user"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تحويل الرصيد الى الصندوق",
      })
    );
    dispatch(setTransactionFromDialog());
    dispatch(indexUserWallet());
  } catch (err) {
    dispatch(setLoading("transaction_from_user"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

const toExcelWalletTransactions = () => async (dispatch, getState) => {
  try {
    const filters = getState().wallet.wallet_transactions.filters;
    dispatch(setLoading("wallet_transactions"));
    const res = await walletApi.exportToExcelWalletsTransactions({
      ...filters,
      user_id: filters.user_id?.id || null,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "wallet-transactions.xlsx";
    a.click();
    dispatch(setLoading("wallet_transactions"));
  } catch (err) {
    dispatch(setLoading("wallet_transactions"));
    throw new Error(err);
  }
};

const toExcelUserWallets = () => async (dispatch, getState) => {
  try {
    const filters = getState().wallet.user_wallet.filters;
    dispatch(setLoading("user_wallet"));
    const res = await walletApi.exportToExcelUserWallets({
      ...filters,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "user-wallets.xlsx";
    a.click();
    dispatch(setLoading("user_wallet"));
  } catch (err) {
    dispatch(setLoading("user_wallet"));
    throw new Error(err);
  }
};

export const Wallet = {
  index,
  indexUserWallet,
  createTransactionFromUser,
  createTransactionToSafe,
  toExcelUserWallets,
  toExcelWalletTransactions,
};

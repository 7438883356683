import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import { Tasks } from "./taskSlice";

const qaApi = factory.get("qa");

const initialState = {
  qa_table: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      is_matching_price: null,
      is_solved: null,
      start_date: null,
      end_date: null,
    },
    dialog: false,
  },
  qa_form: {
    dialog: false,
    loading: false,
    form: {
      maintenance_form_id: null,
      rating: 0,
      actual_price: null,
      is_matching_price: false,
      is_solved: false,
      note: "",
    },
    errors: {},
  },
};

const QaSlice = createSlice({
  name: "Qa",
  initialState,
  reducers: {
    setQaTable: (state, { payload }) => {
      state.qa_table.data = payload.data;
      state.qa_table.total = payload.total;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.qa_table.filters = {
        ...state.qa_table.filters,
        ...payload,
      };
    },
    setFiltersDialog: (state, { payload }) => {
      state.qa_table.dialog = !state.qa_table.dialog;
    },
    resetFilters: (state) => {
      state.qa_table.filters = initialState.qa_table.filters;
    },
    setQaDialog: (state, { payload }) => {
      if (payload) {
        state.qa_form.form = {
          ...state.qa_form.form,
          ...payload,
        };
      } else {
        state.qa_form.form = initialState.qa_form.form;
      }
      // state.qa_form.dialog = !state.qa_form.dialog;
    },
  },
});

export const {
  setQaTable,
  setLoading,
  setFilters,
  resetFilters,
  setFiltersDialog,
  setQaDialog,
} = QaSlice.actions;

export default QaSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("qa_table"));
    const filters = getState().qa.qa_table.filters;
    const filters_params = {
      ...filters,
      // account_type_id: filters.account_type_id?.id || null,
      // customer_id: filters.customer_id?.id || null,
      // city_id: filters.city_id?.id || null,
      // client_id: filters.client_id?.id || null,
      // created_by_id: filters.created_by_id?.id || null,
    };
    const res = await qaApi.index(filters_params);
    dispatch(setQaTable(res.data));
    dispatch(setLoading("qa_table"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setLoading("qa_table"));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("qa_form"));
    await qaApi.create(data);
    dispatch(setLoading("qa_form"));

    dispatch(
      showNotification({
        message: "تم تقييم الصيانة",
        type: "success",
      })
    );
    return true;
  } catch (err) {
    dispatch(setLoading("qa_form"));
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const QaActions = {
  index,
  create,
};

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import appSlice from "./slices/appSlice";
import notificationSlice from "./slices/notificationSlice";
import authSlice from "./slices/authSlice";
import profileSlice from "./slices/profileSlice";
import customersSlice from "./slices/customersSlice";
import reportsSlice from "./slices/reportsSlice";
import statisticsSlice from "./slices/statisticsSlice";
import subscriptionsSlice from "./slices/subscriptionsSlice";
import cabinetsSlice from "./slices/cabinetsSlice";
import usersSlice from "./slices/usersSlice";
import rolesPermissionsSlice from "./slices/rolesPermissionsSlice";
import taskSlice from "./slices/taskSlice";
import expiryCustomersSlice from "./slices/expiryCustomersSlice";
import servicesStatusSlice from "./slices/servicesStatusSlice";
import callCustomerHistorySlice from "./slices/callCustomerHistorySlice";
import clientsSlice from "./slices/clientsSlice";
import ticketsSlice from "./slices/ticketsSlice";
import subscriptionsServicesSlice from "./slices/subscriptionsServicesSlice";
import variablesSlice from "./slices/variablesSlice";
import offers from "./slices/offers";
import announcementSlice from "./slices/announcementSlice";
import QaSlice from "./slices/QaSlice";
import wallet from "./slices/walletSlice";

export const store = configureStore({
  reducer: {
    app: appSlice,
    notification: notificationSlice,
    auth: authSlice,
    profile: profileSlice,
    customers: customersSlice,
    reports: reportsSlice,
    statistics: statisticsSlice,
    subscriptions: subscriptionsSlice,
    cabinets: cabinetsSlice,
    users: usersSlice,
    rolesPermissions: rolesPermissionsSlice,
    task: taskSlice,
    expiryCustomers: expiryCustomersSlice,
    servicesStatus: servicesStatusSlice,
    callCustomerHistory: callCustomerHistorySlice,
    clients: clientsSlice,
    tickets: ticketsSlice,
    subscriptionsServices: subscriptionsServicesSlice,
    variables: variablesSlice,
    offers: offers,
    announcement: announcementSlice,
    qa: QaSlice,
    wallet: wallet,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);

import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import dayjs from "dayjs";
import api from "../../api/apiConfig";
const customersApi = factory.get("customers");
const operationsApi = factory.get("operations");

const initialState = {
  customers: {
    loading: false,
    data: [],
    total: 0,
    page: 1,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      start_date: null,
      end_date: null,
      status_start_date: null,
      status_end_date: null,
      city_id: null,
      client_id: null,
      cabinet_id: null,
      pole_id: null,
      status_id: [],
      created_by_id: null,
      is_active: null,
      subscription_status: null,
      customers_source: null,
      has_no_session: null,
      is_suspended: null,
      is_customer_device: null,
      installed_by: null,
      customer_id_family_no: null,
      is_phone_verified: null,
      is_offer: null,
    },
    filters_dialog: false,
  },
  customer: {
    loading: false,
    progress: 0,
    form: {
      id: "",
      user_name: "",
      password: "",
      pole_id: null,
      port_no: "",
      account_type_id: null,
      offer_id: null,
      cabinet_id: null,
      longitude: "",
      latitude: "",
      date: dayjs().format("YYYY-MM-DD"),
      is_active: true,
      serial_number: "",
      city_id: null,
      teams: [],
      status_id: 0,
      status_by: "",
      status_name: "",
      note: "",
      amount: "0",
      month: "1",
      fees: null,
      v_lan: "",
      customer_status_history: [],
      subscription: [],
      neighborhood: "",
      apartment_number: "",
      street: "",
      house: "",
      client_name: "",
      client_id: null,
      client_tower: "",
      client_note: "",
      ftth_panel_id: "",
      //معلومات التنصيب
      connectors_count: "",
      cable_length: "",
      router_type: null,

      // is_customer_device: false,

      //المرفقات
      id_front: null,
      id_back: null,
      living_card_front: null,
      living_card_back: null,
      signature: null,
      signature_preview: null,
      attachments_preview: [],
      other_attachment: null,
      other_attachments_preview: [],
      cancelled_attachments_preview: [],

      //================= NEW CHANGES =======================
      //الاسم
      full_name: "", //READ ONLY
      first_name: "",
      second_name: "",
      third_name: "",
      forth_name: "",
      family_name: "",
      mother_first_name: "",
      mother_second_name: "",
      mother_third_name: "",
      mother_fourth_name: "",
      // الهوية
      id_type: "0",
      id_no: "",
      id_from: "",
      id_date: null,
      id_memo_no: "",
      id_page_no: "",
      customer_id_family_no: "",
      //الاتصال
      email: "",
      mobile: "",
      second_mobile: "",
      // بطاقة السكن
      living_id_no: "",
      living_id_from: "",
      living_id_date: null,

      //task
      task_id: null,
    },
    data_by_id: {},
    status_history_dialog: false,
    errors: {},
  },
  change_customer: {
    loading: false,
    dialog: false,
    form: {
      customer_name: "",
      client_id: null,
      client_name: null,
      customer_ids: [],
      is_customers_list: false,
      status_id: 15,
      type: "",
    },
  },
  call_center_notes: {
    customer_info: {
      id: "",
      name: "",
    },
    loading: false,
    data: [],
    dialog: false,
  },
  account_types: {
    loading: false,
    data: [],
  },
  status: {
    loading: false,
    data: [],
  },
  cabinet: {
    loading: false,
    data: [],
  },
  note: {
    loading: false,
    form: {
      id: "",
      note: "",
    },
    dialog: false,
  },
  cancel_dialog: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      attachment: null,
      status_id: 11,
      name: "",
    },
  },
  customer_map: {
    dialog: false,
    longitude: "",
    latitude: "",
  },
  customer_info_alwatany: {
    dialog: false,
    customer_id: "",
  },
  client_customers_history_dialog: {
    dialog: false,
    loading: false,
    customer_id: "",
    customer_name: "",
    data: [],
    total: 0,
  },
  installation_cost: {
    loading: false,
    client_with_offer: [],
    client: [],
    not_client: [],
  },
  fees: {
    dialog: false,
    loading: false,
    form: {
      id: null,
      full_name: null,
      old_fees: null,
      old_is_customer_device: false,
      fees: null,
      is_customer_device: false,
    },
  },
  otp: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      name: null,
      otp: null,
    },
  },
  device_status: {
    loading: false,
    dialog: false,
    data: {},
  },
  customer_partial_update: {
    dialog: false,
    loading: false,
    form: {
      id: "",
      user_name: "",
      phone: "",
    },
  },
  update_pole_or_serial: {
    dialog: false,
    form: {
      id: null,
      type: "serial",
      full_name: null,
      cabinet: null,
      pole: null,
      serial: null,
    },
  },
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setCustomerToClientDialog: (state, { payload }) => {
      if (payload) {
        state.change_customer.form.customer_ids = payload.customer_ids;
        state.change_customer.form.customer_name = payload?.customer_name;
        state.change_customer.form.client_name = payload?.client_name;
        state.change_customer.form.type = payload?.type;

        if (payload?.is_customers_list) {
          state.change_customer.form.is_customers_list =
            payload?.is_customers_list;
        }
      } else {
        state.change_customer.form = initialState.change_customer.form;
      }
      state.change_customer.dialog = !state.change_customer.dialog;
    },
    setCustomers: (state, { payload }) => {
      state.customers.data = payload.data;
      state.customers.total = payload.total;
    },
    setFilters: (state, { payload }) => {
      state.customers.filters = {
        ...state.customers.filters,
        ...payload,
      };
    },
    setFiltersDialog: (state, { payload }) => {
      state.customers.filters_dialog = !state.customers.filters_dialog;
    },
    resetFilters: (state) => {
      state.customers.filters = initialState.customers.filters;
    },
    setAccountTypes: (state, { payload }) => {
      payload.forEach((item) => {
        state.account_types.data.push({
          ...item,
        });
      });
    },
    setCustomer: (state, { payload }) => {
      state.customer.data_by_id = payload;
      state.customer.form.id = payload.id;
      state.customer.form.is_phone_verified = payload.is_phone_verified;
      state.customer.form.client_name = payload.client_name;
      state.customer.form.client_id = payload.client_id
        ? {
            id:
              payload.client_id === "null" || !payload.client_id
                ? null
                : payload.client_id,
            name: payload.client_name,
          }
        : null;

      // NEW CHANGES =======================
      // الاسم
      state.customer.form.first_name = payload.first_name;
      state.customer.form.second_name = payload.second_name;
      state.customer.form.third_name = payload.third_name;
      state.customer.form.forth_name = payload.forth_name;
      state.customer.form.family_name = payload.family_name;
      state.customer.form.mother_first_name = payload.mother_first_name;
      state.customer.form.mother_second_name = payload.mother_second_name;
      state.customer.form.mother_third_name = payload.mother_third_name;
      state.customer.form.mother_fourth_name = payload.mother_fourth_name;
      state.customer.form.task_id = payload.task_id;

      // معلومات الاتصال
      state.customer.form.mobile = payload.mobile;
      state.customer.form.email = payload.email || "";
      state.customer.form.second_mobile = payload.second_mobile || "";

      //معلومات الهوية
      state.customer.form.id_type = payload.id_type === 1 ? "1" : "0";
      state.customer.form.id_no = payload.id_no;
      state.customer.form.id_from = payload.id_from;
      state.customer.form.id_date = payload.id_date;
      state.customer.form.id_memo_no = payload.id_memo_no;
      state.customer.form.id_page_no = payload.id_page_no;
      state.customer.form.customer_id_family_no = payload.customer_id_family_no;

      //معلومات السكن
      state.customer.form.living_id_no = payload.living_id_no;
      state.customer.form.living_id_from = payload.living_id_from;
      state.customer.form.living_id_date = payload.living_id_date;

      state.customer.form.first_session_time = payload.first_session_time;
      state.customer.form.client_note = payload.client_note;
      state.customer.form.client_tower = payload.client_tower;
      // state.customer.form.is_customer_device = payload.is_customer_device;
      state.customer.form.full_name = payload.full_name;
      state.customer.form.ftth_panel_id = payload.ftth_panel_id;
      state.customer.form.user_name = payload.user_name;
      state.customer.form.password = payload.password;
      state.customer.form.port_no = payload.port_no;
      state.customer.form.v_lan = payload.v_lan;
      state.customer.form.is_active = payload.is_active;
      state.customer.form.account_type_id = payload.account_type_id;
      state.customer.form.status_by = payload.status_by;
      state.customer.form.subscription = payload.subscription || [];
      state.customer.form.customer_status_history =
        payload.customer_status_history;
      state.customer.form.cabinet_id = payload.cabinet;
      state.customer.form.pole_id = {
        id: payload.pole_id,
        name: payload.pole_name,
      };
      state.customer.form.longitude = payload.longitude;

      state.customer.form.neighborhood = payload.neighborhood;
      state.customer.form.apartment_number = payload.apartment_number;
      state.customer.form.street = payload.street;
      state.customer.form.house = payload.house;

      state.customer.form.note = payload.note || "";
      state.customer.form.fees = payload.operations[0]?.fees || 0;
      state.customer.form.latitude = payload.latitude;
      state.customer.form.date =
        payload.operations.length > 0 ? payload.operations[0].date : null;
      state.customer.form.serial_number = payload.serial_number;
      state.customer.form.status_id = payload.status_id;
      state.customer.form.status_name = payload.status_name;
      // state.customer.form.cancelled = payload.cancelled;
      state.customer.form.city_id = {
        id: payload.city_id,
        name: payload.city_name,
      };
      if (payload.operations.length > 0) {
        payload.operations[0].team.forEach((item) => {
          state.customer.form.teams.push({
            id: item.user_id,
            full_name: item.full_name,
            job_title: item.job_title,
          });
        });
        state.customer.form.router_type = payload.operations[0]?.router_type;
        state.customer.form.cable_length = payload.operations[0]?.cable_length;
        state.customer.form.connectors_count =
          payload.operations[0]?.connectors_count;
      }

      //attachments
      if (payload.attachments.length > 0) {
        payload.attachments.map((item) => {
          state.customer.form.other_attachments_preview.push(item);
        });
      }

      //canceld attachments
      if (payload.cancelled.length > 0) {
        payload.cancelled.map((item) => {
          state.customer.form.cancelled_attachments_preview.push(item);
        });
      }

      payload.id_front &&
        state.customer.form.attachments_preview.push({
          img: payload.id_front,
          tag: "صورة الهوية الامامية",
        });
      payload.id_back &&
        state.customer.form.attachments_preview.push({
          img: payload.id_back,
          tag: "صورة الهوية الخلفية",
        });
      payload.living_card_front &&
        state.customer.form.attachments_preview.push({
          img: payload.living_card_front,
          tag: "بطاقة السكن الامامية",
        });
      payload.living_card_back &&
        state.customer.form.attachments_preview.push({
          img: payload.living_card_back,
          tag: "بطاقة السكن الخلفية",
        });

      state.customer.form.signature_preview = payload.signature || null;
    },
    setAnotherAttachment: (state, { payload }) => {
      if (payload.attachments.length > 0) {
        state.customer.form.other_attachments_preview = [];
        payload.attachments.map((item) => {
          state.customer.form.other_attachments_preview.push(item);
        });
      }
    },
    removeAttachment: (state, { payload }) => {
      const type =
        payload.type === "other_attachments"
          ? "other_attachments_preview"
          : "cancelled_attachments_preview";
      const index = state.customer.form[type].findIndex(
        (item) => item.id === payload.id
      );
      state.customer.form[type].splice(index, 1);
    },
    resetForm: (state) => {
      state.customer = initialState.customer;
    },
    setErrors: (state, { payload }) => {
      state.customer.errors = payload;
    },
    setAllStatus: (state, { payload }) => {
      state.status.data = payload;
    },
    setAllCabinet: (state, { payload }) => {
      state.cabinet.data = payload;
      state.cabinet.loading = false;
    },
    setLastTeam: (state, { payload }) => {
      if (payload.length === 0) {
        state.customer.form.teams = [];
      } else {
        payload.forEach((item) => {
          state.customer.form.teams.push({
            id: item.user_id,
            full_name: item.full_name,
            job_title: item.job_title,
          });
        });
      }
    },
    setStatusHistoryDialog: (state, { payload }) => {
      state.customer.status_history_dialog =
        !state.customer.status_history_dialog;
    },
    setCustomerProgress: (state, { payload }) => {
      state.customer.progress = payload;
    },
    setNoteDialog: (state, { payload }) => {
      if (payload?.id) {
        state.note.form.id = payload.id;
        state.note.form.note = payload.note;
      }
      state.note.dialog = !state.note.dialog;
    },
    setCallCenterNotesDialog: (state, { payload }) => {
      state.call_center_notes.dialog = !state.call_center_notes.dialog;
      if (payload) {
        state.call_center_notes.customer_info = { ...payload };
      } else {
        state.call_center_notes.customer_info =
          initialState.call_center_notes.customer_info;
      }
    },
    setCallCenterNotesData: (state, { payload }) => {
      state.call_center_notes.data = payload;
      state.call_center_notes.loading = false;
    },
    setCancelDialog: (state, { payload }) => {
      if (payload?.id) {
        state.cancel_dialog.form.id = payload.id;
        state.cancel_dialog.form.name = payload.name;
      }
      state.cancel_dialog.dialog = !state.cancel_dialog.dialog;
    },
    setCustomerMap: (state, { payload }) => {
      if (payload?.longitude) {
        state.customer_map.latitude = payload.latitude;
        state.customer_map.longitude = payload.longitude;
      }
      state.customer_map.dialog = !state.customer_map.dialog;
    },
    setClientCustomersHistoryDialog: (state, { payload }) => {
      if (payload?.customer_id) {
        state.client_customers_history_dialog.customer_id = payload.customer_id;
        state.client_customers_history_dialog.customer_name =
          payload.customer_name;
      } else {
        state.client_customers_history_dialog.customer_id = "";
        state.client_customers_history_dialog.customer_name = "";
      }
      state.client_customers_history_dialog.dialog =
        !state.client_customers_history_dialog.dialog;
    },
    setClientCustomersHistoryData: (state, { payload }) => {
      state.client_customers_history_dialog.data = payload ? payload.data : [];
      state.client_customers_history_dialog.total = payload ? payload.total : 0;
    },
    setInstallationCost: (state, { payload }) => {
      state.installation_cost.client_with_offer =
        payload?.client_with_offer || [];
      state.installation_cost.client = payload?.client || [];
      state.installation_cost.not_client = payload?.not_client || [];
      // state.customer.form.fees = payload[0] || 0;
    },
    setCustomerFeesDialog: (state, { payload }) => {
      state.fees.form.id = payload?.id || null;
      state.fees.form.full_name = payload?.full_name || null;
      state.fees.form.old_fees = payload?.fees || null;
      state.fees.form.fees = null;
      state.fees.dialog = !state.fees.dialog;
    },
    setOtp: (state, { payload }) => {
      if (payload?.id) {
        state.otp.form.id = payload.id;
        state.otp.form.name = payload.name;
      }
      state.otp.dialog = !state.otp.dialog;
    },
    setCustomerDeviceStatus: (state, { payload }) => {
      if (payload?.id) {
        state.device_status.data = {
          id: payload?.id,
          full_name: payload?.full_name,
        };
      }
      state.device_status.dialog = !state.device_status.dialog;
    },
    setCustomerPartialUpdateDialog: (state, { payload }) => {
      if (payload?.id) {
        state.customer_partial_update.form = {
          id: payload?.id,
          user_name: payload?.user_name || "",
          mobile: payload?.mobile || "",
        };
      }
      state.customer_partial_update.dialog =
        !state.customer_partial_update.dialog;
    },
    setCustomerPoleOrSerialUpdateDialog: (state, { payload }) => {
      if (payload?.id) {
        state.update_pole_or_serial.form.id = payload?.id || "";
        state.update_pole_or_serial.form.full_name = payload?.full_name || "";
        state.update_pole_or_serial.form.type = payload?.type || "";
        state.update_pole_or_serial.form.cabinet = payload?.cabinet || null;
      }
      state.update_pole_or_serial.dialog = !state.update_pole_or_serial.dialog;
    },
  },
});

export const {
  setCustomers,
  setCustomerProgress,
  setLoading,
  setFilters,
  resetFilters,
  setFiltersDialog,
  setAccountTypes,
  resetForm,
  setCustomer,
  setErrors,
  setAllStatus,
  setAllCabinet,
  setLastTeam,
  setStatusHistoryDialog,
  setNoteDialog,
  setCallCenterNotesDialog,
  setCallCenterNotesData,
  setCancelDialog,
  setCustomerToClientDialog,
  setAnotherAttachment,
  removeAttachment,
  setCustomerMap,
  setClientCustomersHistoryDialog,
  setClientCustomersHistoryData,
  setInstallationCost,
  setCustomerFeesDialog,
  setOtp,
  setCustomerDeviceStatus,
  setCustomerPartialUpdateDialog,
  setCustomerPoleOrSerialUpdateDialog,
} = customersSlice.actions;

export default customersSlice.reducer;

const toNumber = (value) => {
  return typeof value === "number" ? value : parseInt(value.replace(/,/g, ""));
};

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().customers.customers.filters;
    const filters_params = {
      ...filters,
      cabinet_id: filters.cabinet_id?.id || null,
      pole_id: filters.pole_id?.id || null,
      city_id: filters.city_id?.id || null,
      created_by_id: filters.created_by_id?.id || null,
      client_id: filters.client_id?.id || null,
      status_id:
        filters.status_id.length > 0
          ? JSON.stringify(filters.status_id.map((item) => item.id))
          : null,
    };
    dispatch(setLoading("customers"));
    const res = await customersApi.index(filters_params);

    dispatch(setCustomers(res.data));
    dispatch(setLoading("customers"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors,
        type: "error",
      })
    );
    dispatch(setLoading("customers"));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(resetForm());
    dispatch(setLoading("customer"));
    const res = await customersApi.getById(id);
    dispatch(setCustomer(res.data.data[0]));
    dispatch(setLoading("customer"));
  } catch (err) {
    dispatch(setLoading("customer"));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("customer"));
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      switch (key) {
        case "status_id":
          formData.append("status_id", 1);
          break;
        case "task_id":
          formData.append("task_id", data[key] || "");
          break;
        case "city_id":
          formData.append("city_id", data[key].id);
          break;
        case "cabinet_id":
          formData.append("cabinet_id", data[key].id);
          break;
        case "mother_third_name":
          formData.append("mother_third_name", data[key] || "");
          break;
        case "mother_fourth_name":
          formData.append("mother_fourth_name", data[key] || "");
          break;
        case "apartment_number":
          formData.append("apartment_number", data[key] || "");
          break;
        case "living_id_date":
          formData.append("living_id_date", data[key] ? data[key] : "");
          break;
        case "pole_id":
          formData.append("pole_id", data[key].id);
          break;
        case "connectors_count":
          formData.append(
            "connectors_count",
            data[key] ? toNumber(data[key]) : ""
          );
          break;
        case "router_type":
          formData.append("router_type", data[key] || "");
          break;
        case "cable_length":
          formData.append("cable_length", data[key] ? toNumber(data[key]) : "");
          break;
        case "client_id":
          formData.append(
            "client_id",
            data[key]?.id === "null" || !data[key]?.id ? "" : data[key]?.id
          );
          break;
        case "client_note":
          formData.append("client_note", data[key] || "");
          break;
        case "other_attachment":
          data[key].forEach((item, index) => {
            formData.append(`other_attachment[${index}]`, item);
          });
          break;
        case "signature":
          formData.append(`signature`, data[key], "signature.png");
          break;
        case "account_type_id":
          data.account_type_id && formData.append(`account_type_id`, data[key]);
          break;
        case "offer_id":
          data.offer_id && formData.append(`offer_id`, data[key]);
          break;
        case "teams":
          data[key].forEach((item) => {
            formData.append("teams[]", item.id);
          });
          break;
        default:
          formData.append(key, data[key]);
          break;
      }
    });

    await api.post(`/api/v1/customer`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        dispatch(setCustomerProgress(percent));
      },
    });

    dispatch(setLoading("customer"));
    dispatch(
      showNotification({
        message: "تم اضافة المشترك بنجاح",
        type: "success",
      })
    );
    dispatch(setCustomerProgress(0));
    return { status: true };
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch(
      showNotification({
        message: errors[Object.keys(err.response.data.errors)[0]],
        type: "error",
      })
    );

    if (!Array.isArray(errors)) {
      dispatch(setErrors(err.response.data.errors));
    }

    dispatch(setLoading("customer"));
    dispatch(setCustomerProgress(0));

    throw new Error(err);
  }
};
const update = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("customer"));
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "attachments_preview") return;
      if (
        key === "id_front" ||
        key === "id_back" ||
        key === "living_card_front" ||
        key === "living_card_back"
      ) {
        if (data[key] === null) return;
      }

      switch (key) {
        case "mother_third_name":
          formData.append("mother_third_name", data[key] || "");
          break;
        case "task_id":
          formData.append("task_id", data[key] || "");
          break;
        case "mother_fourth_name":
          formData.append("mother_fourth_name", data[key] || "");
          break;
        case "apartment_number":
          formData.append("apartment_number", data[key] || "");
          break;
        case "city_id":
          formData.append("city_id", data[key].id);
          break;
        case "cabinet_id":
          formData.append("cabinet_id", data[key].id);
          break;
        case "pole_id":
          formData.append("pole_id", data[key].id);
          break;
        case "connectors_count":
          formData.append(
            "connectors_count",
            data[key] ? toNumber(data[key]) : ""
          );
          break;
        case "router_type":
          formData.append("router_type", data[key] || "");
          break;
        case "cable_length":
          formData.append("cable_length", data[key] ? toNumber(data[key]) : "");
          break;
        case "living_id_date":
          formData.append("living_id_date", data[key] ? data[key] : "");
          break;
        case "client_id":
          formData.append(
            "client_id",
            data[key]?.id === "null" || !data[key]?.id ? "" : data[key]?.id
          );
          break;
        case "client_note":
          formData.append("client_note", data[key] || "");
          break;
        case "account_type_id":
          data.account_type_id && formData.append(`account_type_id`, data[key]);
          break;
        case "offer_id":
          data.offer_id && formData.append(`offer_id`, data[key]);
          break;
        case "teams":
          // eslint-disable-next-line no-unused-expressions
          data[key].length > 0
            ? data[key].forEach((item) => {
                formData.append("teams[]", item.id);
              })
            : null;
          break;
        default:
          formData.append(key, data[key]);
          break;
      }
    });
    formData.append("_method", "PUT");

    await api.post(`/api/v1/customer/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        dispatch(setCustomerProgress(percent));
      },
    });

    dispatch(setLoading("customer"));
    dispatch(
      showNotification({
        message: "تم تعديل المشترك بنجاح",
        type: "success",
      })
    );
    dispatch(setCustomerProgress(0));
    return { status: true };
  } catch (err) {
    dispatch(
      showNotification({
        message:
          err.response.data.errors[Object.keys(err.response.data.errors)[0]],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("customer"));
    dispatch(setCustomerProgress(0));
    throw new Error(err);
  }
};
const accountTypes = () => async (dispatch, getState) => {
  try {
    const account_types = getState().customers.account_types;
    if (account_types.data.length > 0) return;
    dispatch(setLoading("account_types"));
    const res = await customersApi.accuountTypes();
    dispatch(setAccountTypes(res.data.data));
    dispatch(setLoading("account_types"));
  } catch (err) {
    dispatch(setLoading("account_types"));
    throw new Error(err);
  }
};
const allStatus = () => async (dispatch, getState) => {
  try {
    const status = getState().customers.status;
    if (status.data.length > 0) return;
    dispatch(setLoading("status"));
    const res = await customersApi.allStatus();
    dispatch(setAllStatus(res.data.data));
    dispatch(setLoading("status"));
  } catch (err) {
    dispatch(setLoading("status"));
    throw new Error(err);
  }
};
const allCabinet = (active_only) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("cabinet"));
    const param = active_only ? `?active_only=${active_only}` : "";
    const res = await customersApi.allCabinet(param);
    dispatch(setAllCabinet(res.data.data));
  } catch (err) {
    dispatch(setLoading("cabinet"));
    throw new Error(err);
  }
};
const getLastTeam = () => async (dispatch, getState) => {
  try {
    const res = await operationsApi.getLastTeam();
    dispatch(setLastTeam(res.data.data));
  } catch (err) {
    // dispatch(setLoading("cabinet"));
    throw new Error(err);
  }
};
const deleteCustomer = (id) => async (dispatch, getState) => {
  try {
    await customersApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم حذف المشترك بنجاح",
      })
    );
    dispatch(index());
    return {
      success: true,
    };
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const updateStatus = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("status"));
    await customersApi.updateStatus(data);
    dispatch(
      showNotification({
        type: "success",
        message: "تم تعديل حالة المشترك بنجاح",
      })
    );
    dispatch(setLoading("status"));
    return { status: true };
  } catch (err) {
    dispatch(setLoading("status"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const updateNote = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("note"));
    await customersApi.updateNote(data);
    dispatch(
      showNotification({
        type: "success",
        message: "تم تحديث ملاحظات المشترك بنجاح",
      })
    );
    dispatch(setLoading("note"));

    return { status: true };
  } catch (err) {
    dispatch(setLoading("note"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getCallCenterNotesById = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("call_center_notes"));
    const {
      customer_info: { id },
    } = getState().customers.call_center_notes;
    const res = await customersApi.callCenter(id);
    dispatch(setCallCenterNotesData(res.data.data));
    // return { status: true };
  } catch (err) {
    dispatch(setLoading("call_center_notes"));
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ ما",
      })
    );
    throw new Error(err);
  }
};
const addCallCenterNote = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("call_center_notes"));
    await customersApi.addCallNote({
      ...data,
      customer_id: getState().customers.call_center_notes.customer_info.id,
    });
    dispatch(
      showNotification({
        type: "success",
        message: "تم تحديث ملاحظات المشترك بنجاح",
      })
    );
    dispatch(setCallCenterNotesDialog());
    // return { status: true };
  } catch (err) {
    dispatch(setLoading("call_center_notes"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const toExcel = () => async (dispatch, getState) => {
  try {
    const filters = getState().customers.customers.filters;
    dispatch(setLoading("customers"));
    const res = await customersApi.exportToExcel({
      ...filters,
      cabinet_id: filters.cabinet_id?.id || null,
      pole_id: filters.pole_id?.id || null,
      city_id: filters.city_id?.id || null,
      created_by_id: filters.created_by_id?.id || null,
      client_id: filters.client_id?.id || null,
      status_id:
        filters.status_id.length > 0
          ? JSON.stringify(filters.status_id.map((item) => item.id))
          : null,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = "customers.xlsx";
    a.click();
    dispatch(setLoading("customers"));
  } catch (err) {
    dispatch(setLoading("customers"));
    throw new Error(err);
  }
};
const cancelWithAttachmet = (formData) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("cancel_dialog"));
    const { id } = getState().customers.cancel_dialog.form;
    await customersApi.cancelStatusWithAttachment(id, formData);
    dispatch(
      showNotification({
        type: "success",
        message: "تم تحديث حالة المشترك بنجاح",
      })
    );
    dispatch(setLoading("cancel_dialog"));

    return true;
  } catch (err) {
    dispatch(setLoading("cancel_dialog"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );

    throw new Error(err);
  }
};
const assignClientToCustomer = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("change_customer"));
    await customersApi.assingClientToCustomer({
      ...data,
      client_id: data.client_id?.id,
      customer_ids: data.customer_ids,
    });
    dispatch(
      showNotification({
        type: "success",
        message: "تم تعيين الوكيل للمشترك بنجاح",
      })
    );
    dispatch(setLoading("change_customer"));
    dispatch(setCustomerToClientDialog());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("change_customer"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const uploadAnotherAttachment = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("customer"));
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("attachment", data.other_attachment);
    const res = await api.post(
      `/api/v1/customer/attachment/${data.id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          dispatch(setCustomerProgress(percent));
        },
      }
    );

    dispatch(setLoading("customer"));
    dispatch(
      showNotification({
        message: "تم رفع المرفق بنجاح",
        type: "success",
      })
    );
    dispatch(setCustomerProgress(0));
    dispatch(setAnotherAttachment(res.data.data[0]));
    return true;
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    dispatch(setErrors(err.response.data.errors));
    dispatch(setLoading("customer"));
    dispatch(setCustomerProgress(0));
  }
};
const activateCustomerAlWatanyProject = (id) => async (dispatch, getState) => {
  try {
    const res = await customersApi.activateCustomerAlWatanyProject(id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم نسخ رابط التفعيل",
      })
    );
    dispatch(index());
    return {
      success: true,
      data: res.data,
    };
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const suspendCustomer = (id) => async (dispatch, getState) => {
  try {
    await customersApi.suspendCustomer(id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم  تنفيذ المهمة بنجاح",
      })
    );
    dispatch(index());
    return {
      success: true,
    };
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const getClientCustomersHistoryData = (customer_id) => async (dispatch) => {
  try {
    dispatch(setLoading("client_customers_history_dialog"));
    const res = await customersApi.clientCustomerHistory(customer_id);
    dispatch(setClientCustomersHistoryData(res.data));
    dispatch(setLoading("client_customers_history_dialog"));
  } catch (err) {
    dispatch(setLoading("client_customers_history_dialog"));
    throw new Error(err);
  }
};
const getInstallationCost = () => async (dispatch) => {
  try {
    dispatch(setLoading("installation_cost"));
    const res = await customersApi.installationCost();
    dispatch(setInstallationCost(res.data.data));
    dispatch(setLoading("installation_cost"));
    return res.data.data[0];
  } catch (err) {
    dispatch(setLoading("installation_cost"));
    throw new Error(err);
  }
};
const checkCustomerStatus = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("customers"));
    await customersApi.checkCustomerStatus(id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم تحديث حالة المشترك",
      })
    );
    dispatch(setLoading("customers"));
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("customers"));
    dispatch(
      showNotification({
        type: "error",
        message: "حصل خطآ ما",
      })
    );
    throw new Error(err);
  }
};
const updateCustomerFees = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("fees"));
    await customersApi.updateFees(data);
    dispatch(
      showNotification({
        type: "success",
        message: "تم تحديث اجور تنصيب المشترك",
      })
    );
    dispatch(setLoading("fees"));
    dispatch(setCustomerFeesDialog());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("fees"));
    dispatch(
      showNotification({
        type: "error",
        message: "حصل خطآ ما",
      })
    );
    throw new Error(err);
  }
};
const verifyOtp = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("otp"));
    const { id, otp } = data;
    await customersApi.verifyOtpPhone(id, otp);
    dispatch(
      showNotification({
        type: "success",
        message: "تم تفعيل رقم المشترك",
      })
    );
    dispatch(setLoading("otp"));
    dispatch(setOtp());

    dispatch(getById(data.id));
  } catch (err) {
    dispatch(setLoading("otp"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const changeMultiCustomersStatus = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("change_customer"));
    await customersApi.changeMultiCustomersStatus({
      status_id: data.status_id,
      customer_ids: data.customer_ids,
    });
    dispatch(
      showNotification({
        type: "success",
        message: "تم تغيير حالة المشتركين بنجاح",
      })
    );
    dispatch(setLoading("change_customer"));
    dispatch(setCustomerToClientDialog());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("change_customer"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const checkDeviceStatus = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("customers"));
    await customersApi.checkDeviceStatus(id);
    dispatch(
      showNotification({
        type: "success",
        message: "تم جلب البيانات",
      })
    );
    dispatch(setLoading("customers"));
  } catch (err) {
    dispatch(setLoading("customers"));
    dispatch(
      showNotification({
        type: "error",
        message: "حصل خطآ ما",
      })
    );
    throw new Error(err);
  }
};
const updateCustomerPartial = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("customer_partial_update"));
    await customersApi.updateMobileUserName(data);
    dispatch(setLoading("customer_partial_update"));
    dispatch(getById(data.id));
    dispatch(setCustomerPartialUpdateDialog());
    dispatch(
      showNotification({
        type: "success",
        message: "تم التعديل بنجاح",
      })
    );
  } catch (err) {
    dispatch(setLoading("customer_partial_update"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

const sync = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("customers"));
    await customersApi.sync(id);
    dispatch(setLoading("customers"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم مزامنة البيانات",
      })
    );
    dispatch(getById(id));
  } catch (err) {
    dispatch(setLoading("customers"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

const updatePoleOrSerial = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("update_pole_or_serial"));
    data.type === "serial"
      ? await customersApi.updateSerial(data)
      : await customersApi.updatePole(data);
    dispatch(setLoading("update_pole_or_serial"));

    const typeWordMessage =
      data.type === "pole" ? "تم تحديث العمود" : "تم تحديث السيريال نمبر";
    dispatch(
      showNotification({
        type: "success",
        message: typeWordMessage,
      })
    );
    dispatch(setCustomerPoleOrSerialUpdateDialog());
    dispatch(getById(data.id));
  } catch (err) {
    dispatch(setLoading("update_pole_or_serial"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};

export const Customers = {
  index,
  accountTypes,
  create,
  update,
  getById,
  allStatus,
  allCabinet,
  getLastTeam,
  deleteCustomer,
  updateStatus,
  updateNote,
  getCallCenterNotesById,
  addCallCenterNote,
  toExcel,
  cancelWithAttachmet,
  assignClientToCustomer,
  uploadAnotherAttachment,
  activateCustomerAlWatanyProject,
  suspendCustomer,
  getClientCustomersHistoryData,
  getInstallationCost,
  checkCustomerStatus,
  updateCustomerFees,
  verifyOtp,
  changeMultiCustomersStatus,
  checkDeviceStatus,
  updateCustomerPartial,
  sync,
  updatePoleOrSerial,
};

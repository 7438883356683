import auth from "./endpoints/auth";
import profile from "./endpoints/profile";
import notifications from "./endpoints/notifications";
import customers from "./endpoints/customers";
import city from "./endpoints/city";
import users from "./endpoints/users";
import reports from "./endpoints/reports";
import statistics from "./endpoints/statistics";
import subscriptions from "./endpoints/subscriptions";
import cabinets from "./endpoints/cabinets";
import operations from "./endpoints/operations";
import permissionsRoles from "./endpoints/permissionsRoles";
import task from "./endpoints/task";
import expiry_customers from "./endpoints/expiry-customers";
import services_status from "./endpoints/services-status";
import call_customer_history from "./endpoints/call-customer-history";
import clients from "./endpoints/clients";
import tickets from "./endpoints/tickets";
import variables from "./endpoints/variables";
import subscriptions_services from "./endpoints/subscriptions-services";
import offers from "./endpoints/offers";
import announcement from "./endpoints/announcement";
import qa from "./endpoints/qa";
import wallet from "./endpoints/wallet";

const repositories = {
  customers,
  city,
  users,
  profile,
  auth,
  notifications,
  reports,
  statistics,
  subscriptions,
  cabinets,
  operations,
  permissionsRoles,
  task,
  expiry_customers,
  services_status,
  call_customer_history,
  clients,
  tickets,
  subscriptions_services,
  variables,
  offers,
  announcement,
  qa,
  wallet,
};

export const factory = {
  get: (name) => repositories[name],
};

import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/customer?${p}`);
  },
  exportToExcel(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/customer?${p}`, {
      responseType: "blob",
    });
  },
  accuountTypes() {
    return api.get(`/api/v1/account-type`);
  },
  sync(id) {
    return api.put(`/api/v1/customer/sync/${id}`);
  },
  updateSerial(data) {
    return api.put(`/api/v1/customer/update-serial/${data.id}`, {
      serial: data.serial,
    });
  },
  updatePole(data) {
    return api.put(`/api/v1/customer/update-pole/${data.id}`, {
      pole_id: data?.pole_id?.id,
    });
  },
  getById(id) {
    return api.get(`/api/v1/customer/${id}`);
  },
  delete(id) {
    return api.delete(`/api/v1/customer/${id}`);
  },
  allStatus() {
    return api.get(`/api/v1/status`);
  },
  allCabinet(param) {
    return api.get(`/api/v1/cabinet${param}`);
  },
  updateNote(data) {
    return api.put(`/api/v1/customer/update-note/${data.id}`, data);
  },
  updateStatus(data) {
    return api.put(`/api/v1/customer/update-status/${data.id}`, data);
  },
  callCenter(id) {
    return api.get(
      `/api/v1/call-customer-history?customer_id=${id}&skip=0&take=25`
    );
  },
  addCallNote(data) {
    return api.post(`/api/v1/call-customer-history`, data);
  },
  cancelStatusWithAttachment(id, formData) {
    return api.post(`/api/v1/customer/update-status/${id}`, formData);
  },
  activateCustomerAlWatanyProject(id) {
    return api.post(`/api/v1/customer/activate/${id}`);
  },
  assingClientToCustomer(data) {
    return api.put(`/api/v1/customer/set-client`, {
      client_id: data.client_id,
      customer_ids: data.customer_ids,
    });
  },
  suspendCustomer(id) {
    return api.put(`/api/v1/customer/suspend/${id}`);
  },
  clientCustomerHistory(id) {
    return api.get(`/api/v1/customer/history/${id}`);
  },
  installationCost() {
    return api.get(`/api/v1/installation-cost`);
  },
  checkCustomerStatus(id) {
    return api.put(`/api/v1/customer/update-ftth/${id}`);
  },
  updateFees(data) {
    return api.put(`/api/v1/customer/update-fees/${data.id}`, data);
  },
  verifyOtpPhone(id, otp) {
    return api.put(`/api/v1/customer/otp-validation/${id}`, {
      otp: otp,
    });
  },
  changeMultiCustomersStatus(data) {
    return api.put(`/api/v1/customer/multi-update-status`, {
      status_id: data.status_id,
      customer_ids: data.customer_ids,
    });
  },
  checkDeviceStatus(id) {
    return api.get(`/api/v1/customer/device-status/${id}`);
  },

  updateMobileUserName(data) {
    return api.put(`/api/v1/customer/user-name/${data.id}`, data);
  },
};

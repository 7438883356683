import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const statisticsApi = factory.get("statistics");

const initialState = {
  cards: {
    data: {
      all_installations: 0,
      customer: 0,
      installation_this_month: 0,
      sales: 0,
    },
    loading: false,
  },
  balance_cards: {
    data: {},
    loading: false,
  },
  chart: {
    loading: false,
    filters: {
      start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
      end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
      city_id: null,
    },
    data: [],
  },
  clients_cabinets_chart: {
    loading: false,
    filters: {
      start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
      end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
      client_id: null,
    },
    data: [],
  },
  cabinet_chart: {
    loading: false,
    filters: {
      start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
      end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
      cabinet_id: null,
      city_id: null,
    },
    data: [],
  },
  cabinet_growth: {
    loading: false,
    filters: {
      start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
      end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
      cabinet_id: null,
    },
    data: [],
  },
  pole: {
    loading: false,
    cabinet_id: null,
    data: [],
    dialog: false,
  },
  employee_statistics: {
    loading: false,
    data: {
      installation: {},
      maintenance: {},
      balance: 0,
    },
  },
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    setPoleData: (state, { payload }) => {
      state.pole.data = payload.data.data[0];
      state.pole.loading = false;
    },
    setPoleDialog: (state, { payload }) => {
      state.pole.cabinet_id = payload ? payload : null;
      state.pole.dialog = !state.pole.dialog;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setCards: (state, { payload }) => {
      state.cards.data = payload.data.data;
    },
    setFilterChart(state, { payload }) {
      state.chart.filters = {
        ...state.chart.filters,
        ...payload,
      };
    },
    setChart: (state, { payload }) => {
      state.chart.data = payload.data.data;
    },
    setFilterCabinetChart(state, { payload }) {
      state.cabinet_chart.filters = {
        ...state.cabinet_chart.filters,
        ...payload,
      };
    },
    setFilterCabinetGrowthChart(state, { payload }) {
      state.cabinet_growth.filters = {
        ...state.cabinet_growth.filters,
        ...payload,
      };
    },
    setFilterClientsCabinetChart(state, { payload }) {
      state.clients_cabinets_chart.filters = {
        ...state.clients_cabinets_chart.filters,
        ...payload,
      };
    },
    setClientCabinetChart: (state, { payload }) => {
      state.clients_cabinets_chart.data = payload.data.data;
    },
    setCabinetChart: (state, { payload }) => {
      state.cabinet_chart.data = payload.data.data;
    },
    resetPoleData: (state) => {
      state.pole = initialState.pole;
    },
    setCardsSmsBalance: (state, { payload }) => {
      state.balance_cards.data = payload;
      state.balance_cards.loading = false;
    },
    setCabinetGrowthChart: (state, { payload }) => {
      state.cabinet_growth.data = payload.data.data;
    },
    setEmployeeStatistics: (state, { payload }) => {
      state.employee_statistics.data.installation = payload?.installation;
      state.employee_statistics.data.maintenance = payload?.maintenance;
      state.employee_statistics.data.balance = payload?.balance;
    },
  },
});

export const {
  setLoading,
  setDataTable,
  setCards,
  setChart,
  setFilterChart,
  setFilterCabinetChart,
  setCabinetChart,
  setPoleData,
  setPoleDialog,
  resetPoleData,
  setCardsSmsBalance,
  setFilterClientsCabinetChart,
  setClientCabinetChart,
  setCabinetGrowthChart,
  setFilterCabinetGrowthChart,
  setEmployeeStatistics,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;

//axios
const cards = () => async (dispatch) => {
  try {
    dispatch(setLoading("cards"));
    dispatch(setLoading("balance_cards"));

    const cardsRes = await statisticsApi.cards();
    const smsBalance = await statisticsApi.statisticsBalance();
    dispatch(setCardsSmsBalance(smsBalance.data.data[0]));

    dispatch(setCards(cardsRes));
    dispatch(setLoading("cards"));
  } catch (err) {
    dispatch(setLoading("cards"));
    throw new Error(err);
  }
};
const chart = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("chart"));
    const filters = getState().statistics.chart.filters;
    const chartRes = await statisticsApi.chart(filters);
    dispatch(setChart(chartRes));
    dispatch(setLoading("chart"));
  } catch (err) {
    dispatch(setLoading("chart"));
    throw new Error(err);
  }
};
const clientsCabinetChart = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("clients_cabinets_chart"));
    const filters = getState().statistics.clients_cabinets_chart.filters;
    const chartRes = await statisticsApi.clientCabinetChart(filters);
    dispatch(setClientCabinetChart(chartRes));
    dispatch(setLoading("clients_cabinets_chart"));
  } catch (err) {
    dispatch(setLoading("clients_cabinets_chart"));
    throw new Error(err);
  }
};
const cabinet = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("cabinet_chart"));
    const filters = getState().statistics.cabinet_chart.filters;
    const chartRes = await statisticsApi.cabinet(filters);
    dispatch(setCabinetChart(chartRes));
    dispatch(setLoading("cabinet_chart"));
  } catch (err) {
    dispatch(setLoading("cabinet_chart"));
    throw new Error(err);
  }
};
const poleByCabinetId = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("pole"));
    const chartRes = await statisticsApi.pole(id);
    dispatch(setPoleData(chartRes));
  } catch (err) {
    dispatch(setLoading("pole"));
    throw new Error(err);
  }
};
const reloadBalance = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("balance_cards"));
    const balance = await statisticsApi.ftthSmsBalance();
    dispatch(setCardsSmsBalance(balance.data.data));
  } catch (err) {
    dispatch(setLoading("balance_cards"));
    throw new Error(err);
  }
};
const cabinetGrowth = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("cabinet_growth"));
    const filters = getState().statistics.cabinet_growth.filters;
    const res = await statisticsApi.cabinetGrowth({
      ...filters,
      cabinet_id: filters.cabinet_id?.id || null,
    });
    dispatch(setCabinetGrowthChart(res));
    dispatch(setLoading("cabinet_growth"));
  } catch (err) {
    dispatch(setLoading("cabinet_growth"));
    throw new Error(err);
  }
};
const getEmployeeStatistics = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading("employee_statistics"));
    const res = await statisticsApi.employeeStatistic();
    dispatch(setEmployeeStatistics(res.data.data));
    dispatch(setLoading("employee_statistics"));
  } catch (err) {
    dispatch(setLoading("employee_statistics"));
    throw new Error(err);
  }
};

export const Statistics = {
  cards,
  chart,
  cabinet,
  clientsCabinetChart,
  poleByCabinetId,
  reloadBalance,
  cabinetGrowth,
  getEmployeeStatistics,
};

import api from "../apiConfig";
const paramsFactory = (p) => {
  const params = new URLSearchParams();
  if (p) {
    Object.keys(p).forEach((key) => {
      if (p[key] !== null && p[key] !== undefined && p[key] !== "") {
        params.append(key, p[key]);
      }
    });
  }
  return params;
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  index(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/user-transaction?${p}`);
  },
  indexUserWallet(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/user-wallets?${p}`);
  },
  transactionFromUsers(data) {
    return api.post(`/api/v1/user-transaction`, data);
  },
  transactionToSafe(data) {
    return api.post(`/api/v1/user-transaction/to-safe`, data);
  },
  getSafe() {
    return api.get(`api/v1/user-transaction/get-safe`);
  },
  exportToExcelUserWallets(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/user-wallets?${p}`, {
      responseType: "blob",
    });
  },
  exportToExcelWalletsTransactions(params) {
    const p = paramsFactory(params);
    return api.get(`/api/v1/user-transaction?${p}`, {
      responseType: "blob",
    });
  },
};
